<template>
  <page-title
    title="faq.title"
    video_url="https://www.youtube.com/embed/olkDqbPpNm8"
  />
  <pagination
    :disabled="on_request"
    :resource="pagination"
    :repository="fetch"
  />
  <spinner :loading="on_request" />
  <div class="q-pl-none q-pt-md">
    <q-list v-if="!on_request">
      <q-expansion-item
        v-for="(category, key) in categories"
        :key="`category_${key}`"
        group="categoryGroup"
        :label="category.name"
        :default-opened="key === 0"
        expand-icon="chevron_right"
        expanded-icon="expand_more"
        header-class="text-h6 q-pl-none text-primary item-inverse"
      >
        <q-card class="bg-transparent">
          <q-card-section class="q-pl-none q-pr-none">
            <q-list class="rounded-borders">
              <q-expansion-item
                v-for="(question, qKey) in category.questions"
                :key="`question_${key}_${qKey}`"
                expand-icon="add"
                expanded-icon="remove"
                :default-opened="key === 0 && qKey === 0"
                group="question-group"
                :label="question.question"
                class="bg-white q-pl-none item-shadow q-mb-md"
              >
                <q-card class="q-pl-none">
                  <q-card-section>
                    {{ question.answer }}
                  </q-card-section>
                </q-card>
              </q-expansion-item>
            </q-list>
          </q-card-section>
        </q-card>
      </q-expansion-item>
      <q-separator />
    </q-list>
  </div>
  <div class="row justify-center" v-if="faq_contacts">
    <div class="column text-center">
      <div class="text-h6 q-ma-sm  text-weight-bold text-grey-8">
        {{ $t("faq.still_needs_help") }}
      </div>
      <div class="text-subtitle text-weight-bold text-grey-7">
        {{ $t("faq.enter_in_contact") }}
      </div>
      <q-btn
        class="faq-wpp-button rounded-borders"
        size="2em"
        color="white"
        icon="fab fa-whatsapp"
        icon-right="chevron_right"
      >
        <div class="btn-label-faq ">{{ $t("faq.contact_by_whatsapp") }}</div>
      </q-btn>
      <div class="q-ma-sm text-grey-8">
        <strong class="text-grey-8">
          {{ $t("faq.mobile_label") }}
        </strong>
        {{ toPhone(faq_contacts.mobile) }}
      </div>
      <div class="text-grey-8">
        <strong class="text-grey-8">
          {{ $t("faq.tell_label") }}
        </strong>
        {{ toPhone(faq_contacts.tell) }}
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import pageTitle from "@/modules/main/components/page-title";
import pagination from "@/modules/main/components/pagination";
import spinner from "@/modules/main/components/spinner";
import helpers from "@/shared/helpers";
import { ref } from "vue";

const { useActions, useGetters } = createNamespacedHelpers("faq");

export default {
  components: {
    pageTitle,
    spinner,
    pagination,
  },

  setup() {
    const { fetchFaqQuestionsAction } = useActions(["fetchFaqQuestionsAction"]),
      { toPhone } = helpers.stringManipulations;

    const { faq_contacts, categories, pagination } = useGetters({
      faq_contacts: "getFaqContacts",
      categories: "getCategoriesWithQuestions",
      pagination: "getPaginationResource",
    });

    const on_request = ref(false),
      fetch = (payload = {}) => {
        on_request.value = true;
        fetchFaqQuestionsAction(payload).finally(
          () => (on_request.value = false)
        );
      };

    if (!categories.value.length) fetch();

    return {
      categories,
      faq_contacts,
      toPhone,
      pagination,
      on_request,
      fetch,
    };
  },
};
</script>

<style lang="scss">
.faq-wpp-button:before {
  box-shadow: 0px 0px 9px -4px #adadad;
}
.faq-wpp-button {
  border-radius: 10px;
  margin-top: 15px;
}
.faq-wpp-button .q-icon,
.faq-wpp-button div {
  color: #5b5b65;
}
.faq-wpp-button .q-icon {
  font-size: 35px;
}
.btn-label-faq {
  max-width: 143px;
  font-size: 13px;
  line-height: 1.5;
  font-weight: 500;
}
.q-list hr {
  display: none;
}
</style>
