<template>
  <div v-if="loading" class="row justify-center flex-center spinner-row">
    <label v-if="label" class="q-pr-lg text-weight-bolder">{{
      $t(label)
    }}</label>
    <q-spinner-gears size="3em" color="cyan" />
  </div>
</template>

<script>
export default {
  name: "Spinner",

  props: {
    label: {
      type: String,
      default: "components.spinner_default_label",
    },
    loading: {
      type: Boolean,
    },
  },
};
</script>

<style scoped>
.spinner-row {
  margin-top: 60px;
  margin-bottom: 60px;
}
</style>
